import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Input from './Input'
import Checkbox from './Checkbox'
import FiltersIcon from './icons/filters'
import Button from './Button'
import DateInput from './DateInput'
import { parseDate } from '../util/format'

const initialState = {
    query: '',
    minProtein: '',
    maxProtein: '',
    minHist: '',
    maxHist: '',
    minFat: '',
    maxFat: '',
    minSodium: '',
    maxSodium: '',
    minTvbn: '',
    maxTvbn: '',
    minMoisture: '',
    maxMoisture: '',
    minAntioxidant: '',
    maxAntioxidant: '',
    weight: '',
    noOrders: false,
    startDate: null,
    endDate: null,
}

const WarehouseSpotFilters = ({
    onFilterChange,
}) => {

    const { t } = useTranslation()
    const [filters, setFilters] = useState(initialState)
    const [filtersOpened, setFiltersOpened] = useState(false)

    useEffect(() => {
        if (onFilterChange) onFilterChange(filters)
    }, [])

    const setField = (field, value) => {
        const newVal = {
            ...filters,
            [field]: value,
        }

        setFilters(newVal)

        if (onFilterChange) onFilterChange(newVal)
    }

    return (
        <div
            className={`filters-wrapper${filtersOpened ? ' filters--open' : ''}`}
        >
            <Button
                className='btn-filters'
                icon={<FiltersIcon fill={'#fff'} />}
                label={filtersOpened ? t('filters.filter_close') : t('filters.filter')}
                onClick={() => setFiltersOpened(!filtersOpened)}
            />

            <div className='filters filters--min-max'>
                <div className='filters filters--top'>
                    <h5>{ t('dashboard.filters') }</h5>
                    <Checkbox
                        label={`${t('dashboard.no_orders')}`}
                        value={filters.noOrders}
                        onChange={(e) => setField('noOrders', e.target.checked)}
                    />
                    <Input
                        placeholder={ t('products.weight') }
                        onChange={(e) => setField('weight', e.target.value)}
                        value={filters.weight}
                        type='number'
                        suffix={'kg'}
                    />
                </div>
                <div className='filters filters-row'>
                    <h5>{ t('dashboard.dates') }</h5>
                    <DateInput
                        value={filters.startDate ? new Date(filters.startDate) : null}
                        onChange={(val) => setField('startDate', parseDate(val))}
                    />
                    <DateInput
                        value={filters.endDate ? new Date(filters.endDate) : null}
                        onChange={(val) => setField('endDate', parseDate(val))}
                    />
                </div>
                <div className='filters filters-row'>
                    <h5>{ t('lab.protein') }</h5>
                    <Input
                        placeholder={ t('lab.protein_min') }
                        onChange={(e) => setField('minProtein', e.target.value)}
                        value={filters.minProtein}
                        type='number'
                    />
                    <Input
                        placeholder={ t('lab.protein_max') }
                        onChange={(e) => setField('maxProtein', e.target.value)}
                        value={filters.maxProtein}
                        type='number'
                    />
                </div>
                <div className='filters filters-row'>
                    <h5>{ t('lab.fat') }</h5>
                    <Input
                        placeholder={ t('lab.fat_min') }
                        onChange={(e) => setField('minFat', e.target.value)}
                        value={filters.minFat}
                        type='number'
                    />
                    <Input
                        placeholder={ t('lab.fat_max') }
                        onChange={(e) => setField('maxFat', e.target.value)}
                        value={filters.maxFat}
                        type='number'
                    />
                </div>
                <div className='filters filters-row'>
                    <h5>{ t('lab.histamine') }</h5>
                    <Input
                        placeholder={ t('lab.histamine_min') }
                        onChange={(e) => setField('minHist', e.target.value)}
                        value={filters.minHist}
                        type='number'
                    />
                    <Input
                        placeholder={ t('lab.histamine_max') }
                        onChange={(e) => setField('maxHist', e.target.value)}
                        value={filters.maxHist}
                        type='number'
                    />
                </div>
                <div className='filters filters-row'>
                    <h5>{ t('lab.sodium') }</h5>
                    <Input
                        placeholder={ t('lab.sodium_min') }
                        onChange={(e) => setField('minSodium', e.target.value)}
                        value={filters.minSodium}
                        type='number'
                    />
                    <Input
                        placeholder={ t('lab.sodium_max') }
                        onChange={(e) => setField('maxSodium', e.target.value)}
                        value={filters.maxSodium}
                        type='number'
                    />
                </div>
                <div className='filters filters-row'>
                    <h5>{ t('lab.moisture') }</h5>
                    <Input
                        placeholder={ t('lab.moisture_min') }
                        onChange={(e) => setField('minMoisture', e.target.value)}
                        value={filters.minMoisture}
                        type='number'
                    />
                    <Input
                        placeholder={ t('lab.moisture_max') }
                        onChange={(e) => setField('maxMoisture', e.target.value)}
                        value={filters.maxMoisture}
                        type='number'
                    />
                </div>
                <div className='filters filters-row'>
                    <h5>{ t('lab.tvbn') }</h5>
                    <Input
                        placeholder={ t('lab.tvbn_min') }
                        onChange={(e) => setField('minTvbn', e.target.value)}
                        value={filters.minTvbn}
                        type='number'
                    />
                    <Input
                        placeholder={ t('lab.tvbn_max') }
                        onChange={(e) => setField('maxTvbn', e.target.value)}
                        value={filters.maxTvbn}
                        type='number'
                    />
                </div>
                <div className='filters filters-row'>
                    <h5>{ t('lab.antioxidant') }</h5>
                    <Input
                        placeholder={ t('lab.antioxidant_min') }
                        onChange={(e) => setField('minAntioxidant', e.target.value)}
                        value={filters.minAntioxidant}
                        type='number'
                    />
                    <Input
                        placeholder={ t('lab.antioxidant_max') }
                        onChange={(e) => setField('maxAntioxidant', e.target.value)}
                        value={filters.maxAntioxidant}
                        type='number'
                    />
                </div>
            </div>
        </div>
    )
}

WarehouseSpotFilters.propTypes = {
    onFilterChange: PropTypes.func,
}

export default WarehouseSpotFilters