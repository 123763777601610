import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'urql'
import Modal from './Modal'
import Button from './Button'
import SelectInput from './SelectInput'
import CheckmarkIcon from './icons/checkmark'
import DeleteIcon from './icons/delete'
import DateInput from './DateInput'
import Input from './Input'
import { GetClients, GetWarehouseSpot } from '../graphql/queries'
import { getWarehouseSpotAverageWeight, getWarehouseSpotWeight } from '../util/helpers'
import { RemoveProductsFromClient, SaveProductsToClient, UpdateWarehouseSpot } from '../graphql/mutations'
import { formatDate, parseDate } from '../util/format'
import { useAuth } from '../providers/auth'
import { UserRoles } from '../util/consts'
import Textarea from './Textarea'
import LoadingComponent from './Loading'
import ChangeWarehouse from './ChangeWarehouse'

const ClientsComponent = ({
    warehouseSpotData,
    onUpdate,
}) => {
    const { t } = useTranslation()
    const { user } = useAuth()
    const [clientList, setClientList] = useState(null)
    const [selectedClient, setSelectedClient] = useState(null)
    const [warehouseSpot, setWarehouseSpot] = useState(null)
    const [amount, setAmount] = useState(0)
    const [date, setDate] = useState(null)
    const [comment, setComment] = useState('')
    const [, saveClientProducts] = useMutation(SaveProductsToClient)
    const [, removeClientProducts] = useMutation(RemoveProductsFromClient)

    const [clientQuery, setClientQuery] = useState(null)
    const [clients] = useQuery({
        pause: clientQuery === null,
        query: GetClients,
        variables: {
            page: 0,
            limit: 100,
            orderBy: 'name',
            extraFilters: {
                query: clientQuery,
            },
        },
    })

    useEffect(() => {
        setWarehouseSpot(warehouseSpotData)
    }, [warehouseSpotData])

    useEffect(() => {
        if (clients?.fetching) return
        setClientList(clients?.data?.getClients || [])
    }, [clients?.fetching])

    const handleSave = async () => {
        if (!date || !amount || !selectedClient) return

        try {
            const res = await saveClientProducts({
                clientId: selectedClient?.id,
                labResultId: warehouseSpot?.currentLabResult?.id,
                amount: parseInt(amount, 10),
                pickup: date,
                comment,
            })

            if (
                res?.data?.saveProductsToClient
                && res.data.saveProductsToClient === 'SUCCESS'
                && onUpdate
            ) {
                setAmount(0)
                setDate(null)
                setComment('')
                setSelectedClient(null)

                onUpdate()
            }
        } catch (e) {
            console.log('handleSave error', e)
        }
    }

    const handleAmountChange = (event) => {
        if (event.target.value > warehouseSpot.products.length) {
            setAmount(warehouseSpot.products.length)
            return
        }
        setAmount(event.target.value)
    }

    const handleRemoveBooking = async (id) => {
        try {
            const res = await removeClientProducts({
                bookingId: parseInt(id, 10),
            })

            if (
                res?.data?.removeProductsFromClient
                && res.data.removeProductsFromClient === 'SUCCESS'
                && onUpdate
            ) {
                setWarehouseSpot({
                    ...warehouseSpot,
                    currentLabResult: {
                        ...warehouseSpot.currentLabResult,
                        bookedProducts: [
                            ...warehouseSpot.currentLabResult.bookedProducts.filter(x => x.id !== parseInt(id, 10)),
                        ],
                    },
                })
                onUpdate()
            }
        } catch (e) {
            console.log('handleRemove error', e)
        }
    }



    const getIssuedAmount = (booking) => warehouseSpot?.currentLabResult?.bookedProducts?.find(x => x.client.id === booking.client.id)?.sentProducts?.length || '0'

    if (!warehouseSpot) return (<></>)

    return (
        <div className='warehouse-spot--client'>
            {warehouseSpot?.currentLabResult?.bookedProducts?.length ?
                <div className='warehouse-spot--client-products'>
                    {
                        warehouseSpot?.currentLabResult?.bookedProducts.map(x =>
                            <div
                                key={x.id}
                                className='warehouse-spot--client-product'
                            >
                                <div className='warehouse-spot--client-product-meta'>
                                    <span>{`${x.client.name} (${formatDate(x.pickupDate)})`}</span>
                                    <span>{`${getIssuedAmount(x)} / ${x.amount} tk`}</span>
                                    {
                                        [UserRoles.Admin, UserRoles.Sales].includes(user?.role) ?
                                            <>
                                                {
                                                    getIssuedAmount(x) === x.amount ?
                                                        <Button
                                                            icon={<CheckmarkIcon />}
                                                            onClick={() => handleRemoveBooking(x.id)}
                                                        />
                                                        :
                                                        <Button
                                                            icon={<DeleteIcon />}
                                                            onClick={() => handleRemoveBooking(x.id)}
                                                        />
                                                }
                                            </>
                                            :
                                            <></>
                                    }
                                </div>
                                <div className='warehouse-spot--client-product-comment'>{x.comment}</div>
                            </div>
                        )
                    }
                </div>
                :
                <></>
            }
            {
                [UserRoles.Admin, UserRoles.Sales].includes(user?.role) ?
                    <>
                        <h5>{t('dashboard.client_products')}</h5>
                        <div className='warehouse-spot--client-content'>
                            <div>
                                <SelectInput
                                    placeholder={t('clients.search_client')}
                                    value={selectedClient}
                                    options={clientList}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    onChange={(option) => setSelectedClient(option)}
                                    onInputChange={(value) => setClientQuery(value)}
                                    loadingMessage={t('clients.loading_clients')}
                                />
                                <DateInput
                                    value={date ? new Date(date) : null}
                                    onChange={(val) => setDate(parseDate(val))}
                                />
                                <Input
                                    onChange={handleAmountChange}
                                    type='number'
                                    value={amount}
                                    placeholder={t('dashboard.amount')}
                                />
                            </div>
                            <Textarea
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                placeholder={t('dashboard.comment')}
                            />
                        </div>
                        <Button
                            label={t('dashboard.save_client')}
                            onClick={handleSave}
                        />
                    </>
                    :
                    <></>
            }
        </div>
    )
}

ClientsComponent.propTypes = {
    warehouseSpotData: PropTypes.object,
    onUpdate: PropTypes.func,
}

const WarehouseSpotModal = ({
    show,
    close,
    spotId,
    onAddLabResult,
}) => {

    const { t } = useTranslation()
    const { user } = useAuth()
    const [warehouseSpot, setWarehouseSpot] = useState(null)
    const [antioxidant, setAntioxidant] = useState('')
    const [showChangeSpot, setShowChangeSpot] = useState(false)
    const [, updateSpot] = useMutation(UpdateWarehouseSpot)

    const [spotResult, refetch] = useQuery({
        requestPolicy: 'cache-and-network',
        query: GetWarehouseSpot,
        pause: !spotId,
        variables: {
            id: parseInt(spotId, 10),
        },
    })

    useEffect(() => {
        refetch()
    }, [spotId])

    useEffect(() => {
        if (spotResult.fetching) return
        setWarehouseSpot(spotResult.data?.getWarehouseSpot)
        setAntioxidant(spotResult.data?.getWarehouseSpot?.antioxidant || '')
    }, [spotResult])

    useEffect(() => {
        if (!antioxidant) return
        updateSpot({
            id: warehouseSpot?.id,
            data: {
                antioxidant: parseInt(antioxidant, 10),
            },
        })
    }, [antioxidant])

    const handleClose = () => {
        if (close) close()
        setWarehouseSpot(null)
        setShowChangeSpot(false)
    }

    const handleClientsUpdate = () => {
        refetch()
    }

    const renderLabResult = useMemo(() => {
        if (!warehouseSpot?.currentLabResult) return <></>

        const result = warehouseSpot.currentLabResult

        return (
            <div className='modal-warehouse-spot--lab'>
                <div className='modal-warehouse-spot--lab-result modal-warehouse-spot--lab-result-heading'>
                    <span></span>
                    <div>
                        <span>{'Min'}</span>
                        <span>{'Max'}</span>
                    </div>
                </div>
                <div className='modal-warehouse-spot--lab-result'>
                    <span>{t('lab.protein')}</span>
                    <div>
                        <span>{result.minProtein}</span>
                        <span>{result.maxProtein}</span>
                    </div>
                </div>
                <div className='modal-warehouse-spot--lab-result'>
                    <span>{t('lab.moisture')}</span>
                    <div>
                        <span>{result.minMoisture}</span>
                        <span>{result.maxMoisture}</span>
                    </div>
                </div>
                <div className='modal-warehouse-spot--lab-result'>
                    <span>{t('lab.sodium')}</span>
                    <div>
                        <span>{result.minSodium}</span>
                        <span>{result.maxSodium}</span>
                    </div>
                </div>
                <div className='modal-warehouse-spot--lab-result'>
                    <span>{t('lab.fat')}</span>
                    <div>
                        <span>{result.minFat}</span>
                        <span>{result.maxFat}</span>
                    </div>
                </div>
                <div className='modal-warehouse-spot--lab-result'>
                    <span>{t('lab.histamine')}</span>
                    <div>
                        <span>{result.minHist}</span>
                        <span>{result.maxHist}</span>
                    </div>
                </div>
                <div className='modal-warehouse-spot--lab-result'>
                    <span>{t('lab.tvbn')}</span>
                    <div>
                        <span>{result.minTvbn}</span>
                        <span>{result.maxTvbn}</span>
                    </div>
                </div>
                {
                    result?.comment ?
                        <div className='modal-warehouse-spot--lab-comment'>
                            <p>{result.comment}</p>
                        </div>
                        :
                        <></>
                }
                {
                    result?.loadStart && result.loadEnd ?
                        <div className='modal-warehouse-spot--lab-dates'>
                            <p><span>Tootmise algus:</span> <span>{result?.loadStart ? formatDate(result?.loadStart) : ''}</span></p>
                            <p><span>Tootmise lõpp:</span> <span>{result?.loadEnd ? formatDate(result?.loadEnd) : ''}</span></p>
                        </div>
                        :
                        <></>
                }
            </div>
        )
    }, [warehouseSpot])

    return (
        <Modal
            className={'modal-warehouse-spot'}
            show={show}
            close={handleClose}
            title={warehouseSpot?.name}
        >
            {
                spotResult?.fetching ?
                    <LoadingComponent />
                    :
                    <>
                        <div className='modal-warehouse-spot--data'>
                            <span>{`${t('dashboard.product_count')}`}</span>
                            <span>{warehouseSpot?.products?.length}</span>
                        </div>
                        <div className='modal-warehouse-spot--data'>
                            <span>{`${t('dashboard.average_weight')}`}</span>
                            <span>{getWarehouseSpotAverageWeight(warehouseSpot?.products)}</span>
                        </div>
                        <div className='modal-warehouse-spot--data'>
                            <span>{`${t('dashboard.total_weight')}`}</span>
                            <span>{getWarehouseSpotWeight(warehouseSpot?.products)}</span>
                        </div>
                        {
                            user?.role && [UserRoles.Admin, UserRoles.Sales, UserRoles.Manager].includes(user.role) ?
                                <Button
                                    label={t('warehouse.change_spot')}
                                    className='modal-warehouse-spot--change'
                                    onClick={() => setShowChangeSpot(!showChangeSpot)}
                                />
                                :
                                <></>
                        }
                        {
                            showChangeSpot ?
                                <ChangeWarehouse
                                    data={warehouseSpot}
                                    onSuccess={handleClose}
                                />
                                :
                                <></>
                        }
                        <div className='modal-warehouse-spot--data antioxidant'>
                            <Input
                                label={t('lab.antioxidant')}
                                value={antioxidant}
                                onChange={(e) => setAntioxidant(e.target.value)}
                                disabled={warehouseSpot?.currentLabResult !== null || user?.role !== UserRoles.Manager}
                            />
                        </div>

                        {
                            user?.role === UserRoles.Lab && !warehouseSpot?.currentLabResult ?
                                <Button
                                    className={'modal-warehouse-spot--add-lab-result'}
                                    label={t('lab.add_title')}
                                    onClick={onAddLabResult}
                                />
                                :
                                <></>
                        }

                        {renderLabResult}

                        {
                            !warehouseSpot?.currentLabResult ?
                                <></>
                                :
                                <ClientsComponent
                                    warehouseSpotData={warehouseSpot}
                                    onUpdate={handleClientsUpdate}
                                />
                        }
                    </>
            }
        </Modal>
    )
}

WarehouseSpotModal.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
    spotId: PropTypes.number,
    onAddLabResult: PropTypes.func,
}

export default WarehouseSpotModal