const GetUsers = `
    query getUsers ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
        getUsers (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
            id
            name
            role
            email
        }
    }
`

const GetWarehouseUsers = `
    query getWarehouseUsers {
        getWarehouseUsers {
            email
            name
        }
    }
`

const GetClients = `
    query getClients ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
        getClients (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
            id
            name
            email
            phone
            address
        }
    }
`

const GetWarehouses = `
    query getWarehouses ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
        getWarehouses (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
            id
            name
            spots {
                id
                name
                maxProducts
                currentLabResult {
                    id
                    minProtein
                    maxProtein
                    minHist
                    maxHist
                    minSodium
                    maxSodium
                    minFat
                    maxFat
                    minTvbn
                    maxTvbn
                    antioxidant
                    minMoisture
                    maxMoisture
                    createdAt
                    updatedAt
                    comment
                    bookedProducts {
                        id
                    }
                }
                products {
                    id
                    updatedAt
                    createdAt
                    product {
                        id
                        weight
                    }
                    labResult {
                        id
                    }
                }
                labResults {
                    id
                    minProtein
                    maxProtein
                    minHist
                    maxHist
                    minSodium
                    maxSodium
                    minFat
                    maxFat
                    minTvbn
                    maxTvbn
                    antioxidant
                    minMoisture
                    maxMoisture
                    createdAt
                    updatedAt
                    bookedProducts {
                        id
                    }
                }
            }
        }
    }
`

const GetProducts = `
    query getProducts ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
        getProducts (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
            id
            code
            weight
            updatedAt
            status {
                label
            }
            creator {
                id
                name
            }
            clientProduct {
                createdAt
                client {
                    id
                    name
                }
                spot {
                    id
                    name
                    warehouse {
                        id
                        name
                    }
                }
            }
            productSpot {
                id

                spot {
                    id
                    name

                    warehouse {
                        id
                        name
                    }
                }

                labResult {
                    id
                    bookedProducts {
                        id
                        completed

                        client {
                            id
                            name
                        }
                    }
                }
            }
            createdAt
        }
    }
`

const GetProductByCode = `
    query getProductByCode ($code: String!) {
        getProductByCode (code: $code) {
            id
            code
            creator {
                id
                name
            }
            createdAt
            clientProduct {
                id
                client {
                    id
                    name
                }
            }
            productSpot {
                id
                spot {
                    id
                }
                labResult {
                    bookedProducts {
                        id
                        amount
                        client {
                            id
                            name
                        }
                        sentProducts {
                            id
                        }
                    }
                }
            }
        }
    }
`

const GetLabResults = `
    query getLabResults ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
        getLabResults (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
            id
            minProtein
            maxProtein
            minHist
            maxHist
            minSodium
            maxSodium
            minFat
            maxFat
            minTvbn
            maxTvbn
            antioxidant
            minMoisture
            maxMoisture
            createdAt
            updatedAt
            loadStart
            loadEnd
            comment
            spot {
                id
                name
                maxProducts

                warehouse {
                    id
                    name
                }
            }
            creator {
                id
                name
            }
            productSpots {
                id
                createdAt

                product {
                    id
                    code
                }
            }
        }
    }
`

const GetWarehouseSpot = `
    query getWarehouseSpot ($id: Int!) {
        getWarehouseSpot (id: $id) {
            id
            name
            antioxidant
            maxProducts
            currentLabResult {
                id
                minProtein
                maxProtein
                minHist
                maxHist
                minSodium
                maxSodium
                minFat
                maxFat
                minTvbn
                maxTvbn
                antioxidant
                minMoisture
                maxMoisture
                createdAt
                updatedAt
                loadStart
                loadEnd
                comment
                bookedProducts {
                    id
                    client {
                        id
                        name
                    }
                    comment
                    pickupDate
                    amount
                    sentProducts {
                        id
                        client {
                            id
                            name
                        }
                    }
                }
                sentProducts {
                    id
                    client {
                        id
                        name
                    }
                }
            }
            products {
                id

                product {
                    id
                    weight
                    updatedAt
                    createdAt
                }
            }
        }
    }
`

const GetOrders = `
    query getOrders ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
        getOrders (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
            id
            createdAt
            pickupDate
            amount
            comment
            completed
            labResult {
                minProtein
                maxProtein
                minHist
                maxHist
                minSodium
                maxSodium
                minFat
                maxFat
                minTvbn
                maxTvbn
                antioxidant
                minMoisture
                maxMoisture
                createdAt
                updatedAt
                
                spot {
                    id
                    name
                    warehouse {
                        id
                        name
                    }
                }
            }
            client {
                id
                name
            }
            sentProducts {
                id
            }
        }
    }
`

const GetStatistics = `
    query getStatistics {
        getStatistics {
            year
            month
            current
        }
    }
`

export {
    GetUsers,
    GetWarehouseUsers,
    GetClients,
    GetWarehouses,
    GetProducts,
    GetProductByCode,
    GetLabResults,
    GetWarehouseSpot,
    GetOrders,
    GetStatistics,
}