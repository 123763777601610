const WarehouseAdded = `
    subscription warehouseAdded{
        warehouseAdded {
            id
            name
            spots {
                id
                name
            }
        }
    }
`

const WarehouseUpdated = `
    subscription warehouseUpdated {
        warehouseUpdated {
            id
            name
            spots {
                id
                name
            }
        }
    }
`

const ProductAdded = `
    subscription productAdded{
        productAdded {
            id
            code
            createdAt
            creator {
                id
                name
            }
            spot {
                id
            }
        }
    }
`

const LabResultAdded = `
    subscription labResultAdded{
        labResultAdded {
            id
            minProtein
            maxProtein
            minHist
            maxHist
            minSodium
            maxSodium
            minFat
            maxFat
            minTvbn
            maxTvbn
            antioxidant
            minMoisture
            maxMoisture
            createdAt
            updatedAt
            spot {
                id
                name
                warehouse {
                    id
                    name
                }
            }
            creator {
                id
                name
            }
            productSpots {
                id
                product {
                    id
                    code
                }
            }
        }
    }
`

const LabResultUpdated = `
    subscription labResultUpdated{
        labResultUpdated {
            id
            minProtein
            maxProtein
            minHist
            maxHist
            minSodium
            maxSodium
            minFat
            maxFat
            minTvbn
            maxTvbn
            antioxidant
            minMoisture
            maxMoisture
            createdAt
            updatedAt
            spot {
                id
                name
                warehouse {
                    id
                    name
                }
            }
            creator {
                id
                name
            }
            productSpots {
                id
                product {
                    id
                    code
                }
            }
        }
    }
`

const LabResultRemoved = `
    subscription labResultRemoved{
        labResultRemoved
    }
`

const WarehouseSpotRemoved = `
    subscription warehouseSpotRemoved{
        warehouseSpotRemoved
    }
`

const WarehouseSpotUpdated = `
    subscription warehouseSpotUpdated ($id: Int!) {
        warehouseSpotUpdated (id: $id) {
            previous {
                id
                name
                maxProducts
                currentLabResult {
                    id
                    minProtein
                    maxProtein
                    minHist
                    maxHist
                    minSodium
                    maxSodium
                    minFat
                    maxFat
                    minTvbn
                    maxTvbn
                    antioxidant
                    minMoisture
                    maxMoisture
                    createdAt
                    updatedAt
                    bookedProducts {
                        id
                    }
                }
                products {
                    id
                    updatedAt
                    product {
                        id
                        weight
                    }
                    labResult {
                        id
                    }
                }
                labResults {
                    id
                    minProtein
                    maxProtein
                    minHist
                    maxHist
                    minSodium
                    maxSodium
                    minFat
                    maxFat
                    minTvbn
                    maxTvbn
                    antioxidant
                    minMoisture
                    maxMoisture
                    createdAt
                    updatedAt
                    bookedProducts {
                        id
                    }
                }
            }
            next {
                id
                name
                maxProducts
                currentLabResult {
                    id
                    minProtein
                    maxProtein
                    minHist
                    maxHist
                    minSodium
                    maxSodium
                    minFat
                    maxFat
                    minTvbn
                    maxTvbn
                    antioxidant
                    minMoisture
                    maxMoisture
                    createdAt
                    updatedAt
                    bookedProducts {
                        id
                    }
                }
                products {
                    id
                    updatedAt
                    product {
                        id
                        weight
                    }
                    labResult {
                        id
                    }
                }
                labResults {
                    id
                    minProtein
                    maxProtein
                    minHist
                    maxHist
                    minSodium
                    maxSodium
                    minFat
                    maxFat
                    minTvbn
                    maxTvbn
                    antioxidant
                    minMoisture
                    maxMoisture
                    createdAt
                    updatedAt
                    bookedProducts {
                        id
                    }
                }
            }
        }
    }
`

const OrderRemoved = `
    subscription orderRemoved {
        orderRemoved
    }
`

const OrderUpdated = `
    subscription orderUpdated {
        orderUpdated {
            id
            createdAt
            pickupDate
            amount
            comment
            labResult {
                id
                minProtein
                maxProtein
                minHist
                maxHist
                minSodium
                maxSodium
                minFat
                maxFat
                minTvbn
                maxTvbn
                antioxidant
                minMoisture
                maxMoisture
                createdAt
                updatedAt
                spot {
                    id
                    name
                    maxProducts
                    warehouse {
                        id
                        name
                    }
                }
            }
            client {
                id
                name
            }
            sentProducts {
                id
            }
        }
    }
`

const OrderAdded = `
    subscription orderAdded {
        orderAdded {
            id
            createdAt
            pickupDate
            amount
            comment
            labResult {
                spot {
                    id
                    name
                    maxProducts
                    warehouse {
                        id
                        name
                    }
                }
            }
            client {
                id
                name
            }
            sentProducts {
                id
            }
        }
    }
`

export {
    WarehouseAdded,
    WarehouseUpdated,
    ProductAdded,
    LabResultAdded,
    LabResultUpdated,
    LabResultRemoved,
    WarehouseSpotUpdated,
    WarehouseSpotRemoved,
    OrderRemoved,
    OrderUpdated,
    OrderAdded,
}